<script>
  import Card from "./Card.svelte";

  export let filteredItems;

  let currentIndex = 0;
  let containerWidth = 0;
  let sliderTrackWidth = 0;
  let itemWidth = 300; // Larghezza standard dell'item
  let itemMargin = 10; // Margine tra gli item

  $: visibleItemsCount = Math.floor(
    containerWidth / (itemWidth + itemMargin * 2),
  );
  $: totalItemsWidth = filteredItems.length * (itemWidth + itemMargin * 2);
  $: showLeftArrow = currentIndex > 0;
  $: showRightArrow =
    totalItemsWidth > containerWidth &&
    currentIndex < filteredItems.length - visibleItemsCount;

  function next() {
    if (currentIndex < filteredItems.length - visibleItemsCount) {
      currentIndex++;
    }
  }

  function prev() {
    if (currentIndex > 0) {
      currentIndex--;
    }
  }

  $: console.log("filteredItems")
  $: console.log(filteredItems)
</script>

<div
  class="slider-wrapper relative mx-auto overflow-hidden w-full max-w-screen-lg"
  bind:clientWidth={containerWidth}
>
  <!-- Slider -->
  <div class="slider" bind:clientWidth={sliderTrackWidth}>
    <div
      class="slider-track"
      style="transform: translateX(-{currentIndex *
        (itemWidth + itemMargin * 2)}px);"
    >
      {#each filteredItems as item}
        <div class="slider-item">
          <Card {item} />
        </div>
      {/each}
    </div>
  </div>

  <!-- Left Arrow -->
  {#if showLeftArrow}
    <button on:click={prev} class="arrow left"> &#8592; </button>
  {/if}

  <!-- Right Arrow -->
  {#if showRightArrow}
    <button on:click={next} class="arrow right"> &#8594; </button>
  {/if}
</div>

<style>
  .slider-wrapper {
    max-width: 100%;
    overflow: hidden;
    position: relative;
  }
  .slider {
    display: flex;
    overflow: hidden;
    width: 100%;
  }
  .slider-track {
    display: flex;
    transition: transform 0.3s ease-in-out;
  }
  .slider-item {
    margin: 10px;
    margin-left: 0px;
    flex: 0 0 auto;
    width: 300px;
    height: 85%;
  }
  .arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    z-index: 10;
    height: 50px;
    width: 50px;
    font-size: large;
  }
  .arrow.left {
    left: 10px;
  }
  .arrow.right {
    right: 10px;
  }
</style>
