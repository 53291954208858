<script>
  import { Link } from "svelte-routing"; // Import Link for navigation
</script>

<footer class=" bg-zinc-800 text-white py-8">
  <div class="container mx-auto px-4">
    <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
      
      <!-- Logo Column -->
      <div class="flex items-center">
        <Link to="/">
          <img
            src="/img/logo-toolbox-bianco.png"
            alt="Mepabox Logo"
            class="h-12"
          />
        </Link>
      </div>

      <!-- Contact Information -->
      <div>
        <h2 class="text-lg font-semibold mb-4">Contatti</h2>
        <p>
          <strong>Mail:</strong> <a href="mailto:mepa@toolboxsrl.it" class="text-orange-400 hover:underline">mepa@toolboxsrl.it</a>
        </p>
        <p>
          <strong>Tel:</strong> <a href="tel:+390697859333" class="text-orange-400 hover:underline">+39 0697859333</a>
        </p>
        <p>
          <strong>PEC:</strong> <a href="mailto:toolboxsrl@legalmail.it" class="text-orange-400 hover:underline">toolboxsrl@legalmail.it</a>
        </p>
      </div>

      <!-- Address Information -->
      <div>
        <h2 class="text-lg font-semibold mb-4">Indirizzo</h2>
        <p>
          <strong>Via:</strong> Largo Giuseppe Veratti 29
        </p>
        <p>
          <strong>Cap:</strong> 00146, Roma, Italia
        </p>
        <p>
          <strong>P.IVA - C.F.:</strong> IT14285721008
        </p>
      </div>
    </div>

    <div class="mt-8 text-center text-sm">
      © {new Date().getFullYear()} Toolbox. All rights reserved.
    </div>
  </div>
</footer>
