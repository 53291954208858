<script>
  import { onMount } from "svelte";
  import items from "../items.js";
  import Card from "./Card.svelte";
  import { db } from "../firebase";
  import {
    collection,
    query,
    where,
    getDocs,
    limit,
    startAfter,
    getDoc,
    doc,
    orderBy,
  } from "firebase/firestore";
  import { createEventDispatcher } from "svelte";
  import CategorySidebar from "./CategorySidebar.svelte";
  import { searchStore } from "../stores/searchStore.js";

  let selectedCategory = "";
  let searchStrings = [];

  function handleCategorySelected(event) {
    searchStrings = event.detail.categoryId;
    selectedCategory = searchStrings;
    dispatch("categorySelected", { categoryId: selectedCategory });
  }

  export let searchQuery = "";
  export let selectedCategoryId = $searchStore.categoryId;
  export let showOnlyCategories = false;
  export let showorders = false;

  let filteredItems = [];
  let lastVisibleDocExt = null;
  let hasMoreItemsExt = true;
  let isLoading = false;
  let isLoadingfirst = false;

  const dispatch = createEventDispatcher();

  function parseSearchQuery(query) {
    const terms = [];
    const regex = /"[^"]+"|\S+/g;
    let match;
    while ((match = regex.exec(query)) !== null) {
      terms.push(match[0].replace(/"/g, ""));
    }
    return terms;
  }

  function sortStrings(strings) {
    return strings.sort((a, b) => b.length - a.length);
  }

  function filterDocuments(documents, searchStrings) {
    return documents.filter((doc) =>
      searchStrings.every((searchString) =>
        doc.nameTokens.includes(searchString),
      ),
    );
  }

  // Levenshtein distance algorithm for string similarity
  const levenshteinDistance = (s, t) => {
    if (!s.length) return t.length;
    if (!t.length) return s.length;
    const arr = [];
    for (let i = 0; i <= t.length; i++) {
      arr[i] = [i];
      for (let j = 1; j <= s.length; j++) {
        arr[i][j] =
          i === 0
            ? j
            : Math.min(
                arr[i - 1][j] + 1,
                arr[i][j - 1] + 1,
                arr[i - 1][j - 1] + (s[j - 1] === t[i - 1] ? 0 : 1),
              );
      }
    }
    return arr[t.length][s.length];
  };

  export async function searchProducts(
    searchStrings,
    categoryId,
    limitNumber = 20,
    startAfterDocId = null,
  ) {
    let productsQuery = collection(db, "Mepabox", "Products", "Items");
    productsQuery = query(productsQuery);

    let results = [];
    let lastVisibleDoc;
    let hasMoreItems = false;

    if (categoryId) {
      let categoryQuery = query(
        productsQuery,
        where("category", "array-contains", categoryId),
        orderBy($searchStore.order.value, $searchStore.order.direction),
        limit(limitNumber),
      );

      if (startAfterDocId) {
        const startAfterDoc = await getDoc(
          doc(db, "Mepabox", "Products", "Items", startAfterDocId),
        );
        categoryQuery = query(categoryQuery, startAfter(startAfterDoc));
      }

      const categorySnapshot = await getDocs(categoryQuery);
      results = categorySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      lastVisibleDoc = categorySnapshot.docs[categorySnapshot.docs.length - 1];
      hasMoreItems = categorySnapshot.size === limitNumber;

      return { results, lastVisibleDoc, hasMoreItems };
    }

    showorders = false;

    if (searchStrings[0]) {
      let mepaCodeQuery = query(
        productsQuery,
        where("MEPA", "==", searchStrings[0].toUpperCase()),
      );

      const mepaCodeSnapshot = await getDocs(mepaCodeQuery);
      const mepaResults = mepaCodeSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      results = mepaResults;

      if (mepaResults.length) {
        return { results, lastVisibleDoc, hasMoreItems };
      }
    }

    if (searchStrings.length) {
      let sortedStrings = sortStrings(searchStrings);
      if (sortedStrings[0].length < 3) {
        return { results, lastVisibleDoc, hasMoreItems };
      }

      let nameTokensQuery = query(
        productsQuery,
        where("nameTokens", "array-contains", sortStrings(searchStrings)[0]),
        orderBy($searchStore.order.value, $searchStore.order.direction),
      );

      if (startAfterDocId) {
        const startAfterDoc = await getDoc(
          doc(db, "Mepabox", "Products", "Items", startAfterDocId),
        );
        nameTokensQuery = query(nameTokensQuery, startAfter(startAfterDoc));
      }

      const nameTokensSnapshot = await getDocs(nameTokensQuery);
      const nameTokenResults = nameTokensSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      results.push(
        ...nameTokenResults.filter(
          (doc) => !results.some((result) => result.id === doc.id),
        ),
      );
      lastVisibleDoc =
        nameTokensSnapshot.docs[nameTokensSnapshot.docs.length - 1];

      hasMoreItems = false;

      results = filterDocuments(results, searchStrings);

      results.sort((a, b) => {
        const aDistance = levenshteinDistance(a.name, searchStrings.join(" "));
        const bDistance = levenshteinDistance(b.name, searchStrings.join(" "));
        return aDistance - bDistance;
      });

      if (results.length > 0) {
        return { results, lastVisibleDoc, hasMoreItems };
      }
    }

    let nameTokensQuery = query(
      productsQuery,
      where(
        "nameTokens",
        "array-contains-any",
        searchStrings.filter((s) => s.length > 2),
      ),
      orderBy($searchStore.order.value, $searchStore.order.direction),
      limit(limitNumber),
    );

    if (startAfterDocId) {
      const startAfterDoc = await getDoc(
        doc(db, "Mepabox", "Products", "Items", startAfterDocId),
      );
      nameTokensQuery = query(nameTokensQuery, startAfter(startAfterDoc));
    }

    const nameTokensSnapshot = await getDocs(nameTokensQuery);
    const nameTokenResults = nameTokensSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    results.push(
      ...nameTokenResults.filter(
        (doc) => !results.some((result) => result.id === doc.id),
      ),
    );
    lastVisibleDoc =
      nameTokensSnapshot.docs[nameTokensSnapshot.docs.length - 1];

    hasMoreItems = nameTokensSnapshot.size === limitNumber;

    return { results, lastVisibleDoc, hasMoreItems };
  }

  async function search() {
    try {
      const searchStrings = searchQuery
        ? parseSearchQuery(searchQuery.toLowerCase())
        : [];
      const { results, lastVisibleDoc, hasMoreItems } = await searchProducts(
        searchStrings,
        selectedCategoryId,
        20,
        null,
      );
      filteredItems = results;
      lastVisibleDocExt = lastVisibleDoc;
      hasMoreItemsExt = hasMoreItems;
    } finally {
      isLoading = false;
      isLoadingfirst = false;
      dispatch("searchComplete");
    }
  }

  async function loadMoreItems() {
    isLoading = true;
    const searchStrings = searchQuery
      ? parseSearchQuery(searchQuery.toLowerCase())
      : [];
    const { results, lastVisibleDoc, hasMoreItems } = await searchProducts(
      searchStrings,
      selectedCategoryId,
      20,
      lastVisibleDocExt?.id,
    );
    filteredItems = [...filteredItems, ...results];
    lastVisibleDocExt = lastVisibleDoc;
    hasMoreItemsExt = hasMoreItems;
    isLoading = false;
  }

  onMount(() => {
    isLoadingfirst = true;
    const unsubscribe = searchStore.subscribe((value) => {
      searchQuery = value.query;
      selectedCategoryId = value.categoryId;
      search();
    });
  });
</script>

<div
  class="flex flex-row w-[90vw] mx-auto my-[2vh] xl:w-[80vw] 2xl:w-[90vw] align-center"
>
  {#if showOnlyCategories}
    <div class="w-full">
      <CategorySidebar
        on:categorySelected={handleCategorySelected}
        bind:showOnlyCategories
      />
    </div>
  {:else}
  <div class="sidebar-left w-2/12 min-w-[170px]">
    <div class="m-0 p-0 sticky top-28 z-40">
    <div class="text-2xl pl-2 h-9">Categorie</div>
    <CategorySidebar on:categorySelected={handleCategorySelected} />
    </div>
  </div>
    <div class="w-full md:pl-4 md:w-10/12">
      <div class="text-center text-gray-500 w-full text-xl pb-5 sticky top-[108px] bg-white">
        Non trovi quello che cerchi? <a class="text-2xl font-bold mb-8 text-[#FFA726] text-center" href="/contact">Contattaci</a>
      </div>
      <div
        class="grid justify-items-center md:justify-items-stretch gap-1 md:gap-3
                2xl::grid-cols-4
                lg:grid-cols-3
                md:grid-cols-2
                sm:grid-cols-2
                grid-cols-2"
      >
      <!--  {#if filteredItems.length === 0 && !isLoadingfirst} -->
      {#if isLoading}
      <div class="slider-wrapper relative mx-auto overflow-hidden w-full max-w-screen-lg">
        <!-- Slider Track Placeholder -->
        <div class="slider">
          <div class="slider-track flex">
            {#each Array(3) as _}
              <div class="slider-item flex flex-col justify-between w-[300px] md:w-[350px] p-4 sm:p-6 bg-gray-200 border border-gray-300 shadow rounded-lg animate-pulse">
                <!-- Placeholder Image -->
                <div class="w-full h-48 bg-gray-300 rounded-t-lg"></div>
                <!-- Placeholder Text -->
                <div class="mt-4 flex flex-col space-y-2">
                  <div class="h-6 bg-gray-300 rounded w-3/4"></div>
                  <div class="h-6 bg-gray-300 rounded w-2/4"></div>
                  <div class="h-6 bg-gray-300 rounded w-1/2"></div>
                </div>
              </div>
            {/each}
          </div>
        </div>
    
        <!-- Arrows Placeholder -->
        <button class="arrow left bg-gray-300 animate-pulse"></button>
        <button class="arrow right bg-gray-300 animate-pulse"></button>
      </div>
    {:else}
          {#each filteredItems as item (item.id)}
            <Card {item} />
          {/each}
        {/if}
      </div>
      {#if hasMoreItemsExt && filteredItems.length > 0}
        <div class="flex justify-center mt-4 w-full mx-auto">
          <button
            class="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded mb-10 flex items-center"
            on:click={loadMoreItems}
            disabled={isLoading}
          >
            {#if isLoading}
              <svg class="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8v8H4zm2 5.292l3.292 3.292a8 8 0 0011.416 0l3.292-3.292H6z"
                ></path>
              </svg>
              Caricamento...
            {:else}
              Carica altri
            {/if}
          </button>
        </div>
      {/if}
    </div>
  {/if}
</div>
