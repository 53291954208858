<script>
  import { Link } from "svelte-routing";
  import { cart } from "../stores/stores.js";
  import { navigate } from "svelte-routing";
  import { searchStore } from "../stores/searchStore.js";
  import { currentRoute } from "../stores/pathStore.js";
  import { onMount } from "svelte";

  let cart_sum = 0;
  export let placeholder = "";
  export let selectedCategoryId = null;

  let searchQuery = placeholder;

  const unsubscribe = cart.subscribe((items) => {
    const itemValues = Object.values(items);
    cart_sum = 0;
    itemValues.forEach((item) => {
      cart_sum += item.count;
    });
  });

  function redirectToSearch(event) {
    if (
      (event.code === "Enter" || event.type == "click") &&
      searchQuery.length > 2
    ) {
      searchStore.update((store) => ({
        ...store,
        query: searchQuery,
        categoryId: null,
        categoryMap: [],
      }));

      selectedCategoryId = null; // Reset category when searching by query
      currentRoute.set("/search");
      navigate("/search", { replace: true });
    }
  }

  function resetMap(route) {
    searchQuery = "";
    searchStore.update((store) => ({
      ...store,
      categoryMap: [],
    }));
    currentRoute.set(route);
  }

  onMount(() => {
    const unsubscribe = searchStore.subscribe((store) => {
      searchQuery = store.query;
    });
  });

  let isMenuOpen = false; // Stato per il toggle del menu mobile
</script>

<header class="w-screen fixed top-0 bg-white text-black z-50">
  <div
    class="w-full border-b-[1px] border-b-zinc-600"
  >
  <div class="w-full max-w-[90vw] xl:max-w-[80vw] mx-auto flex items-center py-2 pb-2 my-1">
    <!-- Logo -->
    <Link
      to="/"
      class="flex items-center min-w-[100px]"
      on:click={() => {
        resetMap("/");
      }}
    >
      <img
        src="/img/logo.png"
        alt="Mepazone Logo"
        class="h-8 w-auto object-contain"
      />
    </Link>

    <!-- Barra di ricerca -->
    <div class="search-text-container max-w-[400px] ml-auto flex-grow mr-4 min-w-14">
      <input
        type="text"
        class="search-input w-full rounded-2xl"
        placeholder="Cerca prodotti..."
        bind:value={searchQuery}
        on:keyup={redirectToSearch}
      />
      <!-- Clear button -->
      {#if searchQuery}
        <button
          class="clear-button w-9 my-auto ml-2 cursor-pointer p-1"
          aria-label="Clear search"
          on:click={() => (searchQuery = "")}
        >
          X
        </button>
      {/if}
      <!-- Search button -->
      <img
        class="w-9 my-auto cursor-pointer border-gray-400 p-1"
        aria-label="search button"
        alt="Search"
        src="/img/search.png"
        height="20px"
        on:click={redirectToSearch}
      />
    </div>

    <!-- Carrello (spostato fuori dal menu principale) -->
    <div class="flex items-center">
      <!-- Menu hamburger per mobile -->
      <button
        on:click={() => (isMenuOpen = !isMenuOpen)}
        class="block hidden text-black focus:outline-none mr-4"
      >
        <!-- Icona dell'hamburger -->
        <svg
          class="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16m-7 6h7"
          ></path>
        </svg>
      </button>

      <!-- Menu principale (solo links) -->
      
    </div>

    <!-- Carrello (sempre visibile) -->
    <Link
      to="/checkout"
      class="relative flex items-center"
      on:click={() => {
        resetMap("/checkout");
      }}
    >
      <img
        class="shopping-cart min-w-6"
        aria-label="shopping cart"
        alt="Checkout"
        src="/img/shopping-cart.png"
        style="width: 24px; height: 24px;"
      />
      {#if cart_sum > 0}
        <div
          class="absolute -top-2 -right-2 bg-orange-600 text-white font-bold text-center leading-none {cart_sum >= 100 ? 'text-[8px] pt-[7px] pl-0' : 'text-xs pt-1 pl-[2.1px]'} w-5 h-5 rounded-full shadow-sm"
        >
          {cart_sum}
        </div>
      {/if}
    </Link>
  </div>
</div>
  <div class="justify-center flex pb-2 mt-2 border-b-[1px] border-b-zinc-600">
    <ul class="flex space-x-4 items-center navbar-menu mr-4 font-bold">
      <li
        class="navbar-item cursor-pointer transition-colors hover:text-gray-500"
      >
        <Link
          to="/"
          on:click={() => {
            resetMap("/");
          }}>Home</Link
        >
      </li>
      <li
        class="navbar-item cursor-pointer transition-colors hover:text-gray-500"
      >
        <Link
          to="/contact"
          on:click={() => {
            resetMap("/contact");
          }}>Contatti</Link
        >
      </li>
      <li
        class="navbar-item cursor-pointer transition-colors hover:text-gray-500"
      >
        <Link
          to="/about"
          on:click={() => {
            resetMap("/about");
          }}>Informazioni</Link
        >
      </li>
    </ul>
  </div>

  <!-- Menu mobile dropdown -->
  {#if isMenuOpen}
    <ul
      class="md:hidden flex flex-col items-center space-y-2 pt-4 pb-6 bg-white shadow-lg navbar-menu"
    >
      <li
        class="navbar-item cursor-pointer transition-colors hover:text-gray-500"
      >
        <Link
          to="/"
          on:click={() => {
            resetMap("/");
          }}>Home</Link
        >
      </li>
      <li
        class="navbar-item cursor-pointer transition-colors hover:text-gray-500"
      >
        <Link
          to="/contact"
          on:click={() => {
            resetMap("/contact");
          }}>Contatti</Link
        >
      </li>
      <li
        class="navbar-item cursor-pointer transition-colors hover:text-gray-500"
      >
        <Link
          to="/about"
          on:click={() => {
            resetMap("/about");
          }}>Informazioni</Link
        >
      </li>
    </ul>
  {/if}
</header>

<style>
  /* Stile per disabilitare il disco nel menu */
  ul.navbar-menu {
    list-style: none; /* Sovrascrive `list-style: disc` dal CSS globale */
    padding-left: 0;
    margin-top: 0;
  }
  li.navbar-item {
    list-style: none; /* Sovrascrive `list-style: disc` per ogni elemento `li` */
  }

  .search-text-container {
    display: flex;
    justify-content: space-between;
    border: 1px solid #ccc;
  }

  .search-text-container img {
    border-left: 1px solid #dbdbdb;
    padding: 5px;
  }

  .search-text-container button {
    background-color: #ccc;
    border: none;
    border-radius: 10px;
    margin: 2px;
  }

  input {
    border: 0;
    margin: 0;
  }

  input:focus {
    outline: none;
  }

  .search-text-container {
    display: flex;
    justify-content: space-between;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 2px;
    min-width: 100px;
  }

  .search-text-container img {
    border-left: 1px solid #dbdbdb;
    padding: 5px;
  }

  .search-text-container button {
    background-color: #ccc;
    border: none;
    border-radius: 10px;
    margin: 2px;
  }
</style>
