<script>
  import { navigate } from "svelte-routing";
  import { createEventDispatcher } from "svelte";
  import { doc, getDoc } from "firebase/firestore";
  import { db } from "../firebase";
  import { onMount } from "svelte";
  import { searchStore } from "../stores/searchStore.js";
  import { currentRoute } from "../stores/pathStore";

  let categoriesData = [];
  let selectedCategory = "";
  let hoveredCategory = null;
  let isLoading = true;
  const dispatch = createEventDispatcher();

  export let showOnlyCategories = false;

  function filterByCategory(mainCategory, subCategory, categoryId) {
    selectedCategory = categoryId;
    dispatch("categorySelected", { categoryId: selectedCategory });

    let map;

    if (subCategory === "") {
      map = [mainCategory, categoryId];
    } else {
      map = [mainCategory, subCategory, categoryId];
    }

    searchStore.update((store) => ({
      ...store,
      query: categoryId,
      categoryId: categoryId,
      categoryMap: map,
    }));

    showOnlyCategories = false;
    currentRoute.set("/search");
    navigate("/search", { replace: true });
  }

  function showSubcategories(event, category) {
    event.stopPropagation();
    hoveredCategory = category;
  }

  function handleBackdropClick(event) {
    event.stopPropagation();
    hoveredCategory = null;
  }

  onMount(async () => {
    const categoriesRef = doc(db, "Mepabox", "CategoriesTree");
    const docSnapshot = await getDoc(categoriesRef);

    if (docSnapshot.exists()) {
      categoriesData = docSnapshot.data().categories;
    } else {
      console.error("No categories found!");
    }
    isLoading = false;
  });
</script>

<div class="flex flex-col mb-4 space-y-1 mt-2">
  {#if isLoading}
    {#each Array(6) as _}
      <div
        class="px-4 py-2 bg-gray-200 text-gray-600 text-sm font-semibold cursor-default animate-pulse"
      >
        Loading...
      </div>
    {/each}
  {:else}
  {#if hoveredCategory != null}
  <!-- Backdrop trasparente -->
  <div
    class="fixed inset-0 bg-black bg-opacity-0 z-10"
    on:click={handleBackdropClick}
  ></div>
  {/if}
    {#each categoriesData as categoryData}
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <div
        class="px-4 py-2 bg-gray-100 text-gray-600 text-sm font-semibold cursor-pointer transition duration-300 ease-in-out hover:bg-white z-20"
        on:click={(e) => showSubcategories(e, categoryData.NAME)}
      >
        {categoryData.NAME}

        {#if hoveredCategory === categoryData.NAME}
  

  <!-- Menu di sottocategorie -->
  <div
    class={showOnlyCategories
      ? "absolute top-[168px] z-10 w-[87%] bg-white p-6 shadow-lg h-auto"
      : "absolute left-full top-0 z-20 w-[800px] bg-white p-6 shadow-lg h-auto min-h-full"}
    on:mouseleave={handleBackdropClick}
  >
    <!-- Close button -->
    <button
      class="absolute top-3 right-3 text-gray-500 hover:text-gray-700 font-bold text-2xl z-30"
      on:click|stopPropagation={handleBackdropClick}
      aria-label="Close"
    >
      &times;
    </button>

    <div
      class={showOnlyCategories
        ? "grid grid-cols-1 gap-4"
        : "grid grid-cols-3 gap-4"}
    >
      {#each categoryData.children as subcategory}
        <div>
          <p
            class="font-bold text-lg text-gray-800 cursor-pointer hover:underline"
            on:click|stopPropagation={() =>
              filterByCategory(hoveredCategory, "", subcategory.NAME)}
          >
            {subcategory.NAME}
          </p>
          {#each subcategory.children as microCategory}
            <p
              class="text-gray-600 cursor-pointer hover:underline"
              on:click|stopPropagation={() =>
                filterByCategory(
                  hoveredCategory,
                  subcategory.NAME,
                  microCategory.NAME,
                )}
            >
              {microCategory.NAME}
            </p>
          {/each}
        </div>
      {/each}
    </div>
  </div>
{/if}

      </div>
    {/each}
  {/if}
</div>
