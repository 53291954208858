<script>
  import CategorySidebar from "./CategorySidebar.svelte";
  import CardWrapper from "./CardWrapper.svelte";
  import ProductSlider from "./ProductSlider.svelte";
  import CategorySlider from "./CategorySlider.svelte";
  import { createEventDispatcher } from "svelte";
  import { onMount } from "svelte";
  import { db } from "../firebase";
  import { collection, getDocs } from "firebase/firestore";

  export let categorySelected = "";
  let filteredItems = [];
  let filteredItemsdue = [];
  let isLoading = true;

  onMount(async () => {
    let productsQuery = collection(
      db,
      "Mepabox",
      "HomepageProducts",
      "Items",
    );
    const querySnapshot = await getDocs(productsQuery);
    const results = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    filteredItems = results;


    let productsQuerydue = collection(
      db,
      "Mepabox",
      "Collaborazione",
      "Items",
    );
    const querySnapshotdue = await getDocs(productsQuerydue);
    
    const resultsdue = querySnapshotdue.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    filteredItemsdue = resultsdue;

    isLoading = false; // Stop loading once data is fetched
  });

  let selectedCategory = "";

  const dispatch = createEventDispatcher();

  export let showOnlyCategories = false;

  function handleCategorySelected(event) {
    selectedCategory = event.detail.categoryId;
    dispatch("categorySelected", { categoryId: selectedCategory });
  }

  export let relevantCategories = [
    {
      name: "Pc Portatili",
      image: "/img/_0009_PC PORTATILI.jpg",
      category: "PC Portatili",
    },
    {
      name: "Personal Computer",
      image: "/img/_0008_PERSONAL COMPUTER.jpg",
      category: "Personal Computer",
    },
    {
      name: "Smartphone",
      image: "/img/_0007_SMARTPHONE.jpg",
      category: "Smartphone e Cellulari",
    },
    { name: "TV", image: "/img/_0006_TV.jpg", category: "TV" },
    {
      name: "Periferiche Audio",
      image: "/img/_0005_PERIFERICHE AUDIO.jpg",
      category: "Periferiche Audio",
    },
    {
      name: "Videocamere",
      image: "/img/_0004_VIDEOCAMERE.jpg",
      category: "Videocamere",
    },
    { name: "Droni", image: "/img/_0003_DRONI.jpg", category: "Droni" },
    {
      name: "Stampanti",
      image: "/img/_0002_STAMPANTI.jpg",
      category: "Stampanti",
    },
    {
      name: "Elettrodomestici",
      image: "/img/_0001_ELETTRODOMESTICI.jpg",
      category: "Elettrodomestici",
    },
    {
      name: "Climatizzazione",
      image: "/img/_0000_CLIMATIZZATORI.jpg",
      category: "Climatizzazione",
    },
  ];
</script>

<div
  class="flex flex-col w-[90vw] mx-auto my-[2vh] justify-items-stretch xl:w-[80vw] 2xl:w-[90vw] align-center"
>
  {#if showOnlyCategories}
    <div class="w-full">
      <div class="text-2xl pl-2 h-9">Categorie</div>
      <CategorySidebar
        bind:showOnlyCategories
        on:categorySelected={handleCategorySelected}
      />
    </div>
  {:else}
    <div class="flex flex-row">
      <!-- Aggiunta della classe sidebar-left per nascondere la sidebar sotto i 1000px -->
      <div class="sidebar-left w-2/12 min-w-[170px]">
        <div class="m-0 p-0 sticky top-28 z-40">
        <div class="text-2xl pl-2 h-9">Categorie</div>
        <CategorySidebar
          bind:showOnlyCategories
          on:categorySelected={handleCategorySelected}
        />
      </div>
      </div>
      <div class="w-full md:pl-4 md:w-10/12">
        <div class="text-2xl pl-2 h-9 text-center md:text-justify">
          Prodotti In Evidenza
        </div>
        {#if isLoading}
          
        <div
        class="slider-wrapper relative mx-auto overflow-hidden w-full max-w-screen-lg"
      >
        <!-- Slider -->
        <div class="slider">
          {#each Array(3) as _}
            <div
              class="flex flex-col justify-between w-[90%] md:w-full max-w-sm p-6 mr-2 bg-gray-200 border border-gray-300 shadow rounded-lg animate-pulse"
            >
              <div class="w-full h-48 bg-gray-300 rounded-t-lg"></div>
              <div class="mt-4 space-y-2">
                <div class="h-6 bg-gray-300 rounded w-3/4"></div>
                <div class="h-6 bg-gray-300 rounded w-2/4"></div>
                <div class="h-8 bg-gray-300 rounded w-1/2"></div>
              </div>
            </div>
          {/each}
          </div></div>
        {:else}
        <ProductSlider {filteredItems} />
        {/if}
        <div class="text-2xl pl-2 h-9 mt-3 text-center md:text-justify">
          Prodotti In Collaborazione
        </div>
        {#if isLoading}
          
        <div
        class="slider-wrapper relative mx-auto overflow-hidden w-full max-w-screen-lg"
      >
        <!-- Slider -->
        <div class="slider">
          {#each Array(3) as _}
            <div
              class="flex flex-col justify-between w-[90%] md:w-full max-w-sm p-6 mr-2 bg-gray-200 border border-gray-300 shadow rounded-lg animate-pulse"
            >
              <div class="w-full h-48 bg-gray-300 rounded-t-lg"></div>
              <div class="mt-4 space-y-2">
                <div class="h-6 bg-gray-300 rounded w-3/4"></div>
                <div class="h-6 bg-gray-300 rounded w-2/4"></div>
                <div class="h-8 bg-gray-300 rounded w-1/2"></div>
              </div>
            </div>
          {/each}
          </div></div>
        {:else}
        <ProductSlider filteredItems={filteredItemsdue} />
        {/if}
        <div class="text-2xl pl-2 h-9 text-center mt-3 md:text-justify">
          Categorie
        </div>
        <CategorySlider elements={relevantCategories} />
      </div>
    </div>
    
  {/if}
</div>
<style>
  .slider-wrapper {
    max-width: 100%;
    overflow: hidden;
    position: relative;
  }
  .slider {
    display: flex;
    overflow: hidden;
    width: 100%;
  }
  .slider-track {
    display: flex;
    transition: transform 0.3s ease-in-out;
  }
  .slider-item {
    margin: 10px;
    flex: 0 0 auto;
    width: 300px;
    height: 85%;
  }
  .arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    z-index: 10;
    height: 50px;
    width: 50px;
    font-size: large;
  }
  .arrow.left {
    left: 10px;
  }
  .arrow.right {
    right: 10px;
  }
</style>
