<script>
  import CheckoutItem from "./CheckoutItem.svelte";
  import { cart } from "../stores/stores.js";
  import Modal from "./Modal.svelte";
  import { onMount } from "svelte";
  import { searchStore } from "../stores/searchStore";

  let checkedOut = false;
  let showModal = false;

  let cartItems = [];
  let name = "";
  let phone = "";
  let email = "";
  let description = "";
  let indirizzoente = "";
  let total = 0;
  let countdown =25; // Initialize countdown
  let countdownInterval;

  let sendClicked = false;
  console.log("Elettroservizi-----")
  const unsubscribe = cart.subscribe((items) => {
    //console.log("AAAAAAAA", items);
    cartItems = Object.values(items);
    total = cartItems.reduce((sum, item) => sum + item.price * item.count, 0);
  });

  const createDeal = async () => {
    showModal = true;
    countdownInterval = setInterval(() => {
      countdown -= 1;
      if (countdown <= 0) {
        clearInterval(countdownInterval);
      }
    }, 1000);
    console.log(showModal);
    const dealData = {
      ids: cartItems.map((item) => ({
        PRODUCT_ID: item.id,
        PRODUCT_NAME: item.name,
        PRICE: item.price,
        QUANTITY: item.count,
      })),
      name: name,
      email: email,
      phone: phone,
      indirizzoente: indirizzoente,
      noteprev: description,
    };

    try {
      const response = await fetch(
        "https://bitrix-automation-13891219146.europe-west1.run.app/mepabox/create-deal",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dealData),
        },
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const responseData = await response.json();
      console.log("Deal created successfully:", responseData);
      console.log("url", responseData.url);
      if (responseData.url) {
        checkedOut = true;
        /*
        cart.update((n) => {
          return {};
        });
        
        cart.clear();
        */
        window.location.href =responseData.url;
      }
    } catch (error) {
      console.error("Error creating deal:", error);
      alert("There was an error creating the deal. Please try again.");
    } finally {
      showModal = false;
    }
  };

  function formatPrice(priceString) {
    const priceNumber = parseFloat(priceString);
    const formattedPrice = new Intl.NumberFormat("it-IT", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(priceNumber);

    return "€ " + formattedPrice.replace("€", "").trim();
  }

  const clearcart = () => {
    let answer = confirm("Sei sicuro di voler svuotare il preventivo?");
    if (!answer) {
      return;
    }
    cart.update((n) => {
      return {};
    });
    cart.clear();
  };

  const checkout = () => {
    createDeal();
  };

  onMount(async () => {
    searchStore.update((store) => ({
      ...store,
      query: "",
      categoryId: null,
      categoryMap: [],
    }));
  });

</script>

{#if showModal}
  <Modal>
    <div class="text-center">
      <p class="text-4xl font-bold">Grazie!</p>
      <br />
      <p class="text-2xl pb-5">Attendi qualche secondo...</p>
      <div
        class="loader border-t-4 border-orange-500 rounded-full w-16 h-16 animate-spin mx-auto"
      ></div>
      
      <!-- Countdown display -->
      <p class="mt-4 text-xl">
        Tempo rimanente: {countdown} secondi
      </p>
    </div>
  </Modal>
{/if}

<div
  class="mx-auto mt-[1vh] flex flex-col w-full max-w-[90vw] xl:max-w-[80vw] bg-white p-5 pb-10 rounded-lg shadow-lg mb-10"
>
{#if checkedOut}
<div class="my-20 text-center">
    
    
  <p class="mb-4 text-2xl text-center pb-6">Grazie per averci scelto!</p>
  <p class="mb-4 text-xl text-center pb-8">Preventivo scaricato</p>
  <br />
  <button
    class="mb-4 text-xl text-center w-[90%] md:w-[450px] bg-orange-500 text-white py-2 px-4 rounded"
    on:click={() => (window.location.href = "/")}
  >
    Premi qui per tornare alla home
  </button>
</div>
  
  {:else}
  
  {#if cartItems.length === 0}
  <div class="my-20 text-center w-full">

      
    
      <p class="my-6 text-2xl text-gray-600">Il preventivo è vuoto</p>
      <button
      class="mb-4 text-xl text-center w-[90%] md:w-[450px] bg-orange-500 text-white py-2 px-4 rounded"
      on:click={() => (window.location.href = "/")}
    >
      Premi qui per tornare alla home
    </button>
    
  </div>
  
  {:else}
    <div class="flex flex-col lg:flex-row lg:space-x-4 space-y-4 lg:space-y-0">
      <div class="w-full lg:w-2/3 space-y-4">
        {#each cartItems as item (item.name)}
          <CheckoutItem
            {item}
            class="border-b border-gray-200 pb-4 mb-4 last:border-none last:pb-0 last:mb-0"
          />
        {/each}
      </div>
      <div class="w-full lg:w-1/3 space-y-4 margin-top-lg">
        <div
          class="p-and-clear-cart mb-5">
          <h1 class="text-4xl font-bold text-gray-800">
            Preventivo
          </h1>
          <button class="clear-cart-button bg-red-600 text-white hover:bg-red-800" on:click={clearcart}>Svuota preventivo</button>
        </div>
        
        <div
          class="border-b border-gray-200 pb-4 mb-4 last:border-none last:pb-0 last:mb-0"
        >
          <div class="p-and-clear-cart">
            <p class="text-xl font-bold">Subtotale: {formatPrice(total)}</p>
            
            
          </div>
          {#if total < 300}
            <p class="text-red-500">
              <b>Spese di gestione</b>: € 20 per ordini inferiori a € 300
            </p>
            <p class="text-md">
              Aggiungi {formatPrice(300 - total)} per le spese gratuite.
            </p>
            <p class="text-xl font-bold">
              Totale: {formatPrice(total + 20)}
            </p>
          {:else}
          
            <p class="text-md">
              Spese di gestione <b>gratuite</b>
            </p>
            <p class="text-xl font-bold">
            Totale: {formatPrice(total)}
          </p>
            
          {/if}
          <p class="text-lg text-gray-500">I prezzi non includono l'IVA</p>
        </div>
        <form class="space-y-4">
          <div>
            <label
              for="name"
              class="block text-sm font-medium text-gray-700"
              style={sendClicked && !name ? "color: red;" : ""}>Ente*</label
            >
            <input
              type="text"
              id="name"
              bind:value={name}
              class="mt-1 block w-full shadow-sm text-lg border border-gray-300 rounded-lg p-3"
              required
              style={sendClicked && !name ? "border-color: red;" : ""}
            />
          </div>
          <div>
            <label
              for="email"
              class="block text-sm font-medium text-gray-700"
              style={sendClicked && !email && !phone ? "color: red;" : ""}
              >Email{phone ? "" : "*"}</label
            >
            <input
              type="email"
              id="email"
              autocomplete="email"
              bind:value={email}
              class="mt-1 block w-full shadow-sm text-lg border border-gray-300 rounded-lg p-3"
              required
              style={sendClicked && !email && !phone
                ? "border-color: red;"
                : ""}
            />
          </div>
          <div>
            <label
              for="phone"
              class="block text-sm font-medium text-gray-700"
              style={sendClicked && !phone && !email ? "color: red;" : ""}
              >Telefono{email ? "" : "*"}</label
            >
            <input
              type="text"
              id="phone"
              autocomplete="tel"
              bind:value={phone}
              class="mt-1 block w-full shadow-sm text-lg border border-gray-300 rounded-lg p-3"
              required
              pattern="^\+?[0-9]*$"
              style={sendClicked && !phone && !email
                ? "border-color: red;"
                : ""}
            />
          </div>
          <div>
            <label
              for="indirizzoente"
              class="block text-sm font-medium text-gray-700"
              style={sendClicked && !indirizzoente ? "color: red;" : ""}
              >Indirizzo*</label
            >
            <input
              type="text"
              id="indirizzoente"
              autocomplete="address-line1"
              bind:value={indirizzoente}
              class="mt-1 block w-full shadow-sm text-lg border border-gray-300 rounded-lg p-3"
              required
              style={sendClicked && !indirizzoente ? "border-color: red;" : ""}
            />
          </div>
          <div>
            <label
              for="description"
              class="block text-sm font-medium text-gray-700">Note</label
            >
            <textarea
              id="description"
              bind:value={description}
              class="mt-1 block w-full shadow-sm text-lg border border-gray-300 rounded-lg p-3"
              placeholder="Inserite questo preventivo nella Trattativa Diretta. In alternativa, indicate le vostre esigenze e provvederemo ad aggiornare l'offerta."
            ></textarea>
          </div>
        </form>
        <p class="text-md">
          Il preventivo sarà validato da un operatore prima dell'ordine
        </p>
        <button
          class="w-full py-2 px-6 my-6 bg-gradient-to-r from-[#FFA726]  to-orange-500 text-white font-semibold rounded-lg shadow-md hover:from-orange-600 hover:to-amber-700 transition-transform transform hover:scale-105"
          on:click={() => {
            sendClicked = true;
            if (!name || !(phone || email) || !indirizzoente) {
              return;
            } else {
              checkout();
            }
          }}
        >
          Stampa il Preventivo
        </button>
      </div>
    </div>
  {/if}
  {/if}
</div>

<style>
  .p-and-clear-cart {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .clear-cart-button {
    border: 1px solid;
    padding: 5px;
    border-radius: 0.375rem;
    font-size: 1rem;
    cursor: pointer;
    transition: 200ms;
  }

  @media (max-width: 768px) {
    .margin-top-lg {
      margin-top: 50px !important;
    }
  }
</style>
