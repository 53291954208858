<script>
  // Importa un font moderno
  import "https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap";
</script>

<div class="bg-white pb-12 pt-6">
  <div
    class="content-wrapper max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 xl:w-[80vw] 2xl:w-[90vw]"
  >
    <div class="bg-white pb-5">
      <h1 class="text-4xl font-bold text-[#FFA726] mb-4">
        Toolbox S.r.l.
      </h1>
      <p class="text-lg mb-4">
        <strong class="font-bold">Toolbox S.r.l.</strong> si distingue per la progettazione e realizzazione di impianti tecnologici avanzati, con una particolare attenzione ai sistemi audio-video ed elettrici. <strong class="font-bold">Le nostre soluzioni</strong> sono pensate per adattarsi alle esigenze specifiche di ogni cliente, unendo innovazione, <strong class="font-bold">qualità e affidabilità</strong>.
      </p>

      <p class="text-lg mb-4">
        Il nostro team di professionisti segue ogni fase del progetto, dalla consulenza iniziale alla consegna, garantendo tempi rapidi, sostenibilità e risultati di eccellenza.
      </p>

      <p class="text-lg mb-4">
        Grazie alla <strong class="font-bold">collaborazione</strong> con partner di alto profilo e all'adozione di <strong class="font-bold">standard certificati</strong>, ci impegniamo a offrire un servizio all'avanguardia, confermandoci come un punto di riferimento nel settore.
      </p>

      <p class="text-lg mb-8">
        Operiamo su MePA, la piattaforma digitale per gli acquisti della <strong class="font-bold">Pubblica Amministrazione</strong>, con un'offerta diversificata in numerose categorie merceologiche. Siamo inoltre attivi sulle principali piattaforme di e-procurement, per assicurare interventi rapidi e una copertura completa.
      </p>

      <p class="text-lg mb-8">
        Per importi inferiori alle soglie previste dal Codice degli Appalti, accettiamo affidamenti diretti, offrendo soluzioni tempestive, efficaci e perfettamente conformi alle normative vigenti.
      </p>

      <h2 class="text-3xl font-bold text-[#FFA726] mb-4">I NOSTRI CONTATTI</h2>

      <ul class="text-lg space-y-2">
        <li><strong class="font-bold">Telefono:</strong> 0696521637</li>
        <li>
          <strong class="font-bold">E-mail:</strong> mepa@toolboxsrl.it
        </li>
        <li>
          <strong class="font-bold">PEC:</strong> toolboxsrl@legalmail.it
        </li>
        <li>
          <strong class="font-bold">Orari:</strong> lunedì - venerdì, 09:00 - 18:00
        </li>
      </ul>
    </div>
  </div>
</div>
